import React from 'react'

function Controls({onClick}) {
  return (
    <div className='controls' id='controls'>
        <div className='control'>
            <button onClick={onClick}>X</button>
            <h1>Controls</h1>

            <ul>
                <li>Press <span className='red'>W</span> to move the spaceship up</li>
                <li>Press <span className='red'>S</span> to move the spaceship down</li>
                <li>Press <span className='red'>Q</span> to quit.</li>
                <li>Press <span className='red'>SPACE</span> to shoot laser</li>
            </ul>
        </div>
    </div>
  )
}

export default Controls