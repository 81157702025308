import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import "../styles/Home.css";
import {useAuthState} from "react-firebase-hooks/auth"
import {auth, logout} from "../firebase/firebase"

//? Components
import Navbar from "../components/Navbar";

function Home() {
  document.getElementById("ship").style.display = "none";
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) {
      // TODO: Do we have a loading page?
      return;
    }
    console.log("home", user)
    if (!user) {
      navigate("/login");
    }
  }, [user, loading, navigate])

  return (
    <main className="home" style={{ overflowY: "auto" }}>
      {/* <button onClick={(e) => logout()}>Logout CHeck</button> */}
      <Navbar />

      <div className="flex split">
        <div className="left">
          <h1>Battle &nbsp;&nbsp;to &nbsp;&nbsp;Protect the Space !</h1>
          <Link to="/game">
            <button>Play Now</button>
          </Link>{" "}
          <a href="#how" id="how-link">
            How To Play?
          </a>
        </div>

        <div className="right">
          <img src="images/ship.png" />
        </div>
      </div>

      <div className="flex row shop-link">
        <Link to="/shop">
          <div className="flex row container">
            <img src="images/ship2.png" />
            <div>
              <h2>Buy SpaceShip Skins</h2>
            </div>
          </div>
        </Link>

        <Link to="/shop">
          <div className="flex row container">
            <img src="images/skins.png" style={{marginLeft: '5%'}} />
            <div>
              <h2>Buy Character Skins</h2>
            </div>
          </div>
        </Link>
      </div>

      <div id="how">
        <h1>How to Play?</h1>

        <div className="flex row" style={{justifyContent: 'right'}}>
          <div className="container">
            <h1 className="yellow">01</h1>
            <p>CLICK on the play button to start a new game.</p>
          </div>
        </div>

        <div className="flex row" style={{justifyContent: 'left'}}>
          <div className="container">
            <h1 className="red">02</h1>
            <p>Using W, S and SPACE keys, you can move your spaceship and shoot.</p>
          </div>
        </div>

        <div className="flex row" style={{justifyContent: 'right'}}>
          <div className="container">
            <h1 className="blue">03</h1>
            <p>Kill all the aliens before the time runs out or the aliens kill you.</p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
