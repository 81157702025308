import React, {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import '../styles/Game.css'
import {auth} from '../firebase/firebase'
import {useAuthState} from 'react-firebase-hooks/auth'
import {getDatabase, ref, set, onValue, get, child} from "firebase/database";
import {doc, getDoc} from "firebase/firestore";
//? Components
import Controls from '../components/Controls'
import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";

function Game() {

    document.getElementById('ship').style.display = 'none'

    const [score, setScore] = useState(0)
    const [wave, setWave] = useState(1)
    const [user, loading, error] = useAuthState(auth)

    const navigate = useNavigate()
    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            navigate('/login')
        }
    }, [user, loading])


    useEffect(() => {
        if (!user) {
            return
        }
        const updateScore = async (score) => {
            const db = getDatabase()
            const fs = getFirestore();
            const q = query(collection(fs, "users"), where("email", "==", user.email.toString()));
            const querySnapshot = await getDocs(q);

            const country = querySnapshot.docs[0].data().country;
            const username = user.displayName
            const data = ref(db, `leaderboard/${user.displayName}`);

            get(child(data, "score")).then((snapshot) => {
                if (snapshot.exists()) {
                    console.log(snapshot.val());
                    const currentScore = snapshot.val();
                    if (score > currentScore) {

                        set(data, {
                            score: score,
                            country: country,
                            username: username
                        });
                    }
                } else {
                    set(data, {
                        score: score,
                        country: country,
                        username: username
                    });
                }
            });

        }
        updateScore(score)
    }, [user, score])

    let position = []
    let left = 12
    let int;

    let alien = {
        '1': 5,
        '2': 5,
        '3': 5,
        '4': 5,
        '5': 5,
        '6': 5,
        '7': 5,
        '8': 5,
        '9': 5,
        '10': 5,
        '11': 5,
        '12': 5,
    }

    const shoot = (pos) => {
        let val = 0
        for (let i = 0; i < 4; i++) {
            if (position[i] - pos < 10 && position[i] - pos > -10) {
                val = position[i]
                break
            }
        }

        if (val == 0) {
            return
        }

        const index = position.indexOf(val)

        for (let i = 3; i > -1; i--) {
            const ind = String(index * 3 + i)

            const al = alien[ind]

            if (al > 0) {
                alien[ind] -= 1

                if (al - 1 == 0) {
                    setTimeout(() => {
                        document.getElementById(ind).style.visibility = 'hidden'
                        setScore(prev => prev + 1)

                        left = left - 1

                        if (left == 0) {
                            setScore(prev => prev + 5)
                            setWave(prev => prev + 1)
                            aliens()
                        }
                    }, 2800 - (200 * i))
                }

                break
            }
        }
    }

    const play = (key) => {
        if (key.key == 'w') {
            const ele = document.getElementById('shooter')
            const pos = ele.getBoundingClientRect()
            ele.style.top = String(pos.top - 20) + 'px'
        } else if (key.key == 's') {
            const ele = document.getElementById('shooter')
            const pos = ele.getBoundingClientRect()
            ele.style.top = String(pos.top + 20) + 'px'
        } else if (key.key == ' ') {
            const ele = document.getElementById('shooter')
            const pos = ele.getBoundingClientRect()
            const body = document.getElementById('beams')

            const node = document.createElement('div')
            node.classList.add('beam')
            node.classList.add('shoot')
            node.style.top = String(pos.top + 75) + 'px'

            shoot(pos.top + 75)

            body.appendChild(node)
        } else if (key.key == 'q') {
            document.getElementById('home-link').click()
        }
    }

    const aliens = () => {
        left = 12
        const ele = document.getElementById('aliens')
        ele.innerHTML = ''

        for (let i = 1; i < 13; i++) {
            const node = document.createElement('img')
            node.src = 'images/alien.png'
            node.id = i
            ele.appendChild(node)

            if (position.length < 4) {
                if (i % 3 == 1) {
                    const img = document.getElementById(i)
                    const pos = img.getBoundingClientRect()
                    position.push(pos.top + 30)
                }
            }
        }

        console.log(position)

        ele.classList.add('add')

        setTimeout(() => {
            ele.classList.remove('add')
        }, 5000)
    }

    document.addEventListener('keypress', play)

    useEffect(() => {
        aliens()
    }, [])

    return (
        <main className='game' id='main'>

            <Link to='/' id='home-link'/>

            <Controls onClick={() => {
                document.getElementById('controls').style.display = 'none'
            }}/>

            <div className='flex row scores'>
                <h1>Score: {score}</h1>
                <h1>Wave: Wave {wave}</h1>
            </div>

            <div className='shooter' id='shooter'></div>
            {/* <div className='beam shoot' id='beam'></div> */}

            <div id='beams'>
            </div>

            <div className='aliens' id='aliens'>
            </div>
        </main>
    )
}

export default Game