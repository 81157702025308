import React, {useEffect} from "react";
import "../styles/Leaderboard.css";

//? Components
import Navbar from "../components/Navbar";
import {getDatabase, ref, onValue} from "firebase/database";


function Leaderboard() {

    //get scores from firebase and sort them and then show them in the leaderboard
    const [data, setData] = React.useState([]);
    useEffect(() => {
        const db = getDatabase();
        const k = ref(db, "leaderboard");
        onValue(k, (snapshot) => {
            let x = snapshot.val();
            console.log(x);
            // sort the data
            let sortedData = Object.entries(x).sort((a, b) => b[1].score - a[1].score);
            console.log("sd", sortedData)
            setData(sortedData);

        });

    }, [])

    return (
        <main>
            <Navbar/>

            <div className="leaderboard">
                <h1>High Scores</h1>

                <table>
                    <thead>
                    <tr>
                        <th className="red">Rank</th>
                        <th style={{color: '#169f00'}}>Country</th>
                        <th className="blue">Username</th>
                        <th className="yellow">Score</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        data ? data.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item[1].country}</td>
                                <td>{item[1].username}</td>
                                <td>{item[1].score}</td>
                            </tr>
                        )
                    ): <tr><td>Loading...</td></tr>}

                    {/*<tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>India</td>
                        <td>CORE</td>
                        <td>39627</td>
                    </tr>*/}
                    </tbody>
                </table>
            </div>
        </main>
    );
}

export default Leaderboard;
