import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/Components.css'
import {auth, logout} from "../firebase/firebase"


function Navbar() {
  
  const log = () => {
    logout()
    document.getElementById('login').click()
  }

  return (
    <nav>
        <Link to='/login' id='login' />
        <div className='flex row' style={{justifyContent: 'space-between'}}>
            <Link to='/'><h1><img src='images/ship2.png' />Cosmovaders</h1></Link>
            <button onClick={log}>Logout</button>
        </div>

        <div className="flex row">
            <Link to='/game' className='red'>Play</Link>
            <Link to='/about' className='blue'>About</Link>
            <Link to='/events' className='yellow'>Events</Link>
            <Link to='/friends' className='red'>Friends</Link>
            <Link to='/leaderboard' className='blue'>Leaderboard</Link>
        </div>
    </nav>
  )
}

export default Navbar