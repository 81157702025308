import React, {useEffect, useMemo, useState} from 'react'
import {Link, useNavigate } from 'react-router-dom'
import '../styles/Auth.css'
import {auth, registerWithEmailAndPassword} from "../firebase/firebase";
import countryList from "react-select-country-list";
import Select from "react-select";
import {useAuthState} from "react-firebase-hooks/auth";

function Register() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [user, loading, err] = useAuthState(auth);

    // countries
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault()
        registerWithEmailAndPassword(name, email, password, value).then(r => {
            if (r) {
                setError(r.message);
                console.log("r", r.message)
            } else {
                navigate('/');
            }
        });
    }

    useEffect(() => {
        if (loading) {
            return;
        }
        if (user) {
            navigate("/")
        }
    }, [user, loading])

  return (
    <main className='flex auth'>
        <h1><img src='images/ship2.png' />Cosmovaders</h1>

        <p className={"error"}>{error}</p> <br/>

        <form style={{marginTop: "-3%"}}>
            <input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} required/> <br/>
          <input type="text" placeholder="Username" onChange={(e) => setEmail(e.target.value)} required/> <br/>
          <center>
          <Select options={options} className="select" placeholder="Country" isSearchable onChange={(e) => {
                setValue(e.label)
            }} /></center> <br/>
        <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required/> <br/>
        {/* <input type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} required/> <br/> */}

        <button onClick={(e) => handleSubmit(e)}>Sign Up</button> <br/>
        <span>Already have an account? <Link to='/login'>Sign In</Link></span>
      </form>
    </main>
  )
}

export default Register