import React, {useEffect} from 'react'
import '../styles/Friends.css'

//? Components
import Navbar from '../components/Navbar'

import {useNavigate} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, db} from "../firebase/firebase";
import {
    setDoc,
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc, doc, getDoc,
} from "firebase/firestore";

function Friends() {

    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth);
    useEffect(() => {
        if (loading) {
            return;
        }
        console.log("home", user)
        if (!user) {
            navigate("/login");
        }
    }, [user, loading, navigate])

    /*
    * Working: sabse pehle check users mei requests hai ya nahi
    * if there are load them in the requests section
    * if there are no requests then show "No Requests"
    * on accept click remove the request from the requests section and add the user to the friends section
    * on reject click remove the request from the requests section
    */

    const [requests, setRequests] = React.useState([]);
    const [friends, setFriends] = React.useState([]);
    const [newRequest, setNewRequest] = React.useState();
    const fb = getFirestore()
    useEffect(() => {
        if (!user) {
            return;
        }
        const q = query(collection(fb, "users"), where("email", "==", user.email));
        getDocs(q).then((querySnapshot) => {
            // console.log(querySnapshot.docs[0].data().friends)
            setRequests(querySnapshot.docs[0].data().requests)
            setFriends(querySnapshot.docs[0].data().friends)
        })

    }, [user])
    const accept = async (username) => {
        const q = query(collection(fb, "users"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        const docId = querySnapshot.docs[0].id;
        const docRef = doc(fb, "users", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // update the friends array
            const friends = docSnap.data().friends;
            friends.push(username);
            const requests = docSnap.data().requests;
            const index = requests.indexOf(username);
            if (index > -1) {
                requests.splice(index, 1);
            } else {
                console.log("error")
            }
            console.log(requests)
            console.log(friends)
            await setDoc(doc(db, "users", user.displayName), {
                email: user.email,
                country: docSnap.data().country,
                username: user.displayName,
                uid: user.uid,
                friends: friends,
                requests: requests
            })
            // from username's doc remove the user from requests and add to friends
            const q2 = query(collection(fb, "users"), where("username", "==", username));
            const querySnapshot2 = await getDocs(q2);
            const docId2 = querySnapshot2.docs[0].id;
            const docRef2 = doc(fb, "users", docId2);
            const docSnap2 = await getDoc(docRef2);
            if (docSnap2.exists()) {
                const friends2 = docSnap2.data().friends;
                friends2.push(user.displayName);
                const requests2 = docSnap2.data().requests;
                const index2 = requests2.indexOf(user.displayName);
                if (index2 > -1) {
                    requests2.splice(index2, 1);
                } else {
                    console.log("error")
                }
                console.log(requests2)
                console.log(friends2)
                await setDoc(doc(db, "users", username), {
                    email: docSnap2.data().email,
                    username: username,
                    uid: docSnap2.data().uid,
                    country: docSnap2.data().country,
                    friends: friends2,
                    requests: requests2
                })
            } else {
                console.log("No such document friend!");
            }
        } else {
            console.log("No such document user!");
        }
    }

    const reject = async () => {
        // update the requests array of the user
        const q = query(collection(fb, "users"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        const docId = querySnapshot.docs[0].id;
        const docRef = doc(fb, "users", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let requests = docSnap.data().requests;
            const index = requests.indexOf(newRequest);
            if (index > -1) {
                requests.splice(index, 1);
            } else {
                requests = []
            }
            console.log(requests)
            await setDoc(doc(db, "users", user.displayName), {
                email: user.email,
                country: docSnap.data().country,
                username: user.displayName,
                uid: user.uid,
                friends: docSnap.data().friends,
                requests: requests
            })
        }
    }

    const addFriend = async () => {
        const q = query(collection(fb, "users"), where("username", "==", newRequest));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length === 0) {
            alert("User not found")
            return;
        }
        const docId = querySnapshot.docs[0].id;
        const docRef = doc(fb, "users", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const requests = docSnap.data().requests;
            requests.push(user.displayName);
            await setDoc(doc(db, "users", newRequest), {
                username: newRequest,
                country: docSnap.data().country,
                uid: docSnap.data().uid,
                email: docSnap.data().email,
                friends: docSnap.data().friends,
                requests: requests
            })
        } else {
            console.log("No such document!");
        }
    }

    return (
        <main style={{overflowY: 'auto'}}>
            <Navbar/>

            <div className='friend-req'>
                <input placeholder={"username of friend"} onChange={(e) => {setNewRequest(e.target.value)}}/> <button onClick={addFriend}>Send Request</button>
                <h1>Friend Requests:-</h1>

                <div className='flex row req-container'>
                    {requests ? requests.map((item, index) => (
                    <div className='flex row req'>
                        <div style={{textAlign: 'center'}}>
                            <img src='images/core.png'/>
                            <h2>{item}</h2>
                        </div>

                        <div>
                            <button onClick={() => {accept(item)}}>Accept</button>
                            <br/><br/>
                            <button id='reject' onClick={reject}>Reject</button>
                        </div>
                    </div>
                )) : <h2>No Requests</h2>}
                </div>
            </div>
            <div className="leaderboard">
                <table>
                    <thead>
                    <tr>
                        <th className="red">S. no</th>
                        <th style={{color: '#169f00'}}>Friends</th>
                        <th className="blue">Play</th>
                    </tr>
                    </thead>

                    <tbody>
                    {friends ? friends.map((item, index) => (
                    <tr>
                        <td>{index + 1}</td>
                        <td>{item}</td>
                        <td>
                            <button className='yay'>challenge</button>
                        </td>
                    </tr>
                    )): <tr><td>No Friends</td></tr>}
                    </tbody>
                </table>
            </div>
        </main>
    )
}

export default Friends