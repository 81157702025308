import {initializeApp} from "firebase/app";
import {
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    updateProfile
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc, setDoc, doc,
} from "firebase/firestore";
import {getDatabase, ref, set, onValue, get, child} from "firebase/database";
import {useAuthState} from "react-firebase-hooks/auth";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: "exun-hackathon.firebaseapp.com",
    projectId: "exun-hackathon",
    storageBucket: "exun-hackathon.appspot.com",
    messagingSenderId: process.env.REACT_APP_F_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_F_APP_ID,
    measurementId: process.env.REACT_APP_F_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email, password) => {
    email = email + "@corexexun.com";
    try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        console.log(res.user);
    } catch (err) {
        console.error(err);
        // alert(err.message);
        return err;
    }
};

const registerWithEmailAndPassword = async (name, email, password, country) => {
    const username = email;
    email = email + "@corexexun.com";
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password)

        const user = res.user;
        // update current user's profile to add display name
        await updateProfile(user, {
            displayName: username,
            username: username
        });

        await setDoc(doc(db, "users", user.displayName), {
            uid: user.uid,
            username: name,
            email,
            "country": country,
            "friends": [],
            "requests": []
        });
    } catch (err) {
        console.error(err);
        return err
    }
};
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

/*const updateLeaderboard = async (score) => {
    // get country of user from firestore
    const user = auth.currentUser;
    const userRef = collection(db, "users");
    const querySnapshot = await getDocs( query(userRef, where("username", "==", user.uid)));
    const country = querySnapshot.docs[0].data().country;
    // real time leaderboard
    const leaderboardRef = ref(getDatabase(app), `leaderboard/${country}`);
    // check current score of user by uid
    const currentScore = await get(leaderboardRef.child(user.uid)).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            return 0;
        }
    }

    await set(leaderboardRef, {
        [user.uid]: score
    });
}*/


const logout = () => {
    signOut(auth);
};
export {
    auth,
    db,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
};