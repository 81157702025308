import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import './index.css';

//? Web Pages
import Login from './pages/Login';
import Register from './pages/Register';
import Leaderboard from './pages/Leaderboard';
import Game from './pages/Game';
import Home from './pages/Home';
import Friends from './pages/Friends';
import Not_Found from './pages/Not_Found';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/leaderboard' element={<Leaderboard />} />
        <Route path='/game' element={<Game />} />
        <Route path='/friends' element={<Friends />} />
        <Route path='*' element={<Not_Found />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
