import React from 'react'

function Not_Found() {
  return (
    <main className='flex' style={{flexDirection: 'column'}}>
        <h1 style={{textAlign: 'center', fontSize: '7vw', marginTop: 0}}>404 <br/>Not Found</h1>
    </main>
  )
}

export default Not_Found