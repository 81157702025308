import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import '../styles/Auth.css'
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, logInWithEmailAndPassword} from "../firebase/firebase";


function Login() {

        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
        const [user, loading, error] = useAuthState(auth);
        const navigate = useNavigate();

        const [err, setErr] = useState("");

        useEffect(() => {
            console.log("login", user)
            if (loading) {
                // TODO: Do we have a loading page?
                return;
            }
            if (user) navigate("/");
        }, [user, loading]);

  return (
    <main className='flex auth'>
      <h1><img src='images/ship2.png' />Cosmovaders</h1>

        <p className={"error"}>{err}</p>

      <form>
        <input type="text" placeholder="Username" onChange={(e) => setEmail(e.target.value)} required/> <br/>
        <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required/> <br/>

        <button onClick={(e) => {
            e.preventDefault()
            setErr("");
            logInWithEmailAndPassword(email, password).then((err) => {
            if (err) {
                setErr(err.message);
            } else {
                navigate("/");
            }
        })}}>Sign In</button> <br/>
        <span>Don't have an account? <Link to='/register'>Sign Up</Link></span>
      </form>
    </main>
  )
}

export default Login